<template>
    <div>

        <div class="card-toolbar mb-5 d-flex justify-content-between">
          <div>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
              <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>

          </div>
          <div>
            <router-link v-if="$can('inventory_checking.inventory_checking_history')" to="/items/inventory-checking-history" class="btn btn-primary font-weight-bolder h-100">
              {{ $t('MENU.inventory_checking_history') }}
            </router-link>
          </div>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="inventories">{{$t('inventory_checking.inventory')}}</label>
                            <multiselect v-model="inventory"
                                         id="inventories"
                                         :placeholder="$t('inventory_checking.inventory')"
                                         label="name"
                                         track-by="id"
                                         :options="inventories"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="product_id">{{$t('inventory_checking.product')}}</label>
                            <div class="d-flex search-item-group">
                                <select name="" id="" v-model="f_by" class="custom-select" style="width: 100px;">
                                    <option value="name" selected>{{$t('items.name')}}</option>
                                    <option value="sku_code">{{$t('items.sku_code')}}</option>
                                </select>
                                <multiselect v-model="product"
                                             id="product_id"
                                             :placeholder="$t('inventory_checking.product')"
                                             :label="f_by?f_by:'sku_code'"
                                             track-by="id"
                                             :options="items"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             :internal-search="false"
                                             @search-change="getItems(f_by, $event)">
                                </multiselect>
                            </div>
                        </div>

                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="actual_qty" slot-scope="props">
                        <span v-if="!props.row.last_validated_date">
                            {{props.row.actual_qty}}
                        </span>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <template v-if="props.row.last_validated_date || !props.row.inventory_validation_id">
                            <v-btn icon color="pink" v-if="$can('inventory_checking.update')" @click="goToEdit(props.row.inventory_id,props.row.item_id)">
                                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('create')">mdi-file</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn icon color="pink" v-if="$can('inventory_checking.update')" :to="'/items/inventory-checking/edit/'+props.row.inventory_validation_id">
                                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <template v-if="!props.row.is_confirmed">
                            <b-dropdown v-if="props.row.inventory_validation_id" :id="'dropdown-offset_'+props.row.inventory_validation_id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
                                <a v-if="$can('inventory_checking.confirm_qty') && props.row.last_validated_date" class="dropdown-item" href="javascript:;" @click="actionIncreaseDecrease(props.row.inventory_validation_id, props.row.actual_qty)">
                                    {{ $t('inventory_checking.confirm_qty') }}
                                </a>
                                <a v-else-if="$can('inventory_checking.approve')" class="dropdown-item" href="javascript:;" @click="makeApprove(props.row.inventory_validation_id)">
                                    {{ $t('approve') }}
                                </a>
                            </b-dropdown>
                        </template>
                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
        <b-modal ref="confirm-qty" size="md" hide-footer :title="$t('inventory_checking.confirm_qty')">
            <div>
                <div class="form-group row">
                    <div class="col-lg-12 mb-5">
                        <label for="qty">{{$t('inventory_checking.qty')}}</label>
                        <input name="qty" id="qty" type="number" min="0" v-model="qty" class="form-control" :class="validation && validation.qty ? 'is-invalid' : ''">
                        <span v-if="validation && validation.qty" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.qty[0] }}
                        </span>
                    </div>
                </div>
                <div class="card-footer pl-0 pr-0 pb-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="reset" class="btn btn-primary mr-2" @click="saveIncreaseDecrease">{{ $t('save') }}</button>
                            <button type="reset" class="btn btn-secondary" @click="hideModal">{{ $t('cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-inventory-checking",
        data() {
            return {
                mainRoute: 'items/inventory-checking',
                subMainRoute: 'items/inventory-check',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    inventory_id: this.$route.query.inventory_id ? this.$route.query.inventory_id : null,
                    product_id: null,
                    from_date: null,
                    to_date: null,
                    last_validated_date: null,
                    validator_user: null,
                },
                // status_list: [],
                columns: ['inventory_name', 'item_name', 'available_stock_qty', 'inventory_last_validated_date', 'last_value', 'actual_qty', 'actions'],
                data: [],
                user: null,
                users: [],

                qty: null,
                inventory_validation_id: null,
                validation: null,

                inventories: [],
                items: [],
                inventory: null,
                product: null,
                f_by: 'name',

            }
        },
        watch: {
            inventory: function (val) {
                if (val) {
                    this.filters.inventory_id = val.id;
                } else {
                    this.filters.inventory_id = null;
                }
            },
            product: function (val) {
                if (val) {
                    this.filters.product_id = val.id;
                } else {
                    this.filters.product_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        inventory_name: that.$t('inventory_checking.inventory'),
                        item_name: that.$t('inventory_checking.product'),
                        available_stock_qty: that.$t('inventory_checking.available_stock_qty'),
                        inventory_last_validated_date: that.$t('inventory_checking.last_validated_date'),
                        last_value: that.$t('inventory_checking.last_value'),
                        actual_qty: that.$t('inventory_checking.actual_qty'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.inventory_checking")}]);
            this.getInventories();
        },
        methods: {
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.inventory_id = null;
                this.filters.product_id = null;
                this.inventory = null;
                this.product = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            getInventories() {
                ApiService.get(this.mainRouteDependency + `/inventories`).then((response) => {
                    this.inventories = response.data.data;
                });
            },

            getItems(f_by = null, filter = null) {
              let _f_by = f_by? f_by : 'name';
              if (filter && filter.length >= 3 && _f_by)
                    ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
                        this.items = response.data.data;
                    });
            },
            goToEdit(inventory_id, item_id) {
                this.$router.push({
                    name: 'inventory-checking.edit', query: {
                        inventory_id: inventory_id,
                        item_id: item_id,
                    }
                });
            },
            makeApprove(inventory_validation_id) {
                this.$confirmAlert(this.$t('do_you_want_make_it_approved'), this.actionApprove, inventory_validation_id);
            },

            actionApprove(inventory_validation_id) {
                ApiService.patch(`${this.subMainRoute}/approved/${inventory_validation_id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            actionIncreaseDecrease(inventory_validation_id, qty) {
                this.qty = qty;
                this.inventory_validation_id = inventory_validation_id;
                this.showModal();
            },
            showModal() {
                this.$refs['confirm-qty'].show();
            },
            hideModal() {
                this.$refs['confirm-qty'].hide();
                this.qty = null;
                this.inventory_validation_id = null;
            },
            saveIncreaseDecrease() {
                ApiService.patch(`${this.subMainRoute}/update_qty/${this.inventory_validation_id}`, {
                    qty: this.qty,
                }).then((response) => {
                    this.getFetch();
                    this.hideModal();
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
        },
    };
</script>
